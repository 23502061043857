
import _ from 'lodash';
import {HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID} from "@/constants/trip-types.enum";


export function isTimeSelectable(time, blockDates, edited){

    if (blockDates[edited.date]) {
        return (
            _.find(blockDates[edited.date], {'time': time}) !== undefined
            &&
            _.find(blockDates[edited.date], {'id': edited.id,'charter_package': edited.beforeCharterPackage,'time': time}) === undefined
        )
            ? false : true
    }
    return true
}

export function checkReservedDate(date, blockDates, edited, boatNamesTripsPictures, thisBoatTripNameId){

    if( edited.boat === 0 ){
        return false;
    }

    if(
        blockDates[date] &&

        _.find(blockDates[date], {
            'id': edited.id,
            'charter_package': edited.charterPackage,
            'time': edited.time
        })
    ){
        return false;
    }

    let boatId = edited.boatObject !== null ? edited.boatObject.id : edited.boat.id;
    let boatIndex = boatId - 1;

    let maintenance_from = boatNamesTripsPictures[boatIndex].maintenance_from;
    let maintenance_to = boatNamesTripsPictures[boatIndex].maintenance_to;

    if( Date.parse(date) >= Date.parse(maintenance_from) && Date.parse(date) <= (Date.parse(maintenance_to)) ){
        return true;
    }

    let tripId = thisBoatTripNameId[edited.charterPackage];

    let tripActiveFrom = null;
    let tripActiveTo = null;
    boatNamesTripsPictures[boatIndex].trips.map(function(trip){

        if( tripId == trip.id && tripActiveFrom == null && tripActiveTo == null ){
            tripActiveFrom = trip.active_from;
            tripActiveTo = trip.active_to;
        }
    });

    if( tripActiveFrom != null || tripActiveTo != null ){

        if( tripActiveFrom != null && tripActiveTo == null && Date.parse(date) < Date.parse(tripActiveFrom) ){
            return true;
        }

        if( tripActiveTo != null && tripActiveFrom == null && Date.parse(date) > Date.parse(tripActiveTo) ){
            return true;
        }

        if( tripActiveTo != null && tripActiveFrom != null && (Date.parse(date) < Date.parse(tripActiveFrom) || Date.parse(date) > (Date.parse(tripActiveTo))) ){
            return true;
        }
    }

    if ( boatId === TEASER_ID && edited.charterPackage === HALF_DAY_TRIP ) {

        if (
            blockDates[date]
            &&
            (
                ( _.find(blockDates[date], {'charter_package': HALF_DAY_TRIP }) === undefined && _.find(blockDates[date], {'charter_package': HARBOR_CRUISE }) === undefined )
                ||
                ( blockDates[date].length == 2 && _.find(blockDates[date], {'charter_package': HALF_DAY_TRIP }) !== undefined )  /*blockDates[date].time.length === 2*/
                ||
                blockDates[date].length == 3
            )
        ) {
            return true;
        }
        return false;
    }

    if ( boatId === TEASER_ID && edited.charterPackage === HARBOR_CRUISE ) {

        if (
            blockDates[date]
            &&
            (
                ( _.find(blockDates[date], {'charter_package': HARBOR_CRUISE }) === undefined && !_.find(blockDates[date], {'charter_package': HALF_DAY_TRIP }) === undefined )

                ||

                blockDates[date].length == 3
            )
        ) {
            return true;
        }

        return false;
    }

    return Object.keys(blockDates).includes(date)
}