export const HARBOR_CRUISE = 'Harbor Cruise';
export const HALF_DAY_TRIP = 'A ½ Day Trip';
export const FULL_DAY_TRIP = 'Full Day Trip';
export const THREE_FOURTHS_DAY_TRIP = 'A ¾ Day Trip';
export const MIN_DAYS_TO_CHANGE_HALF_DAY_TRIP = 14;
export const TEASER_ID = 3;

export const INSHORE_NAME = 'Inshore Fishing';
export const INSHORE_BASE_PEOPLE = 2;
export const INSHORE_BASE_HOURS = 4;
export const INSHORE_BASE_COST = 625;
export const INSHORE_ADD_COST = 150;
export const INSHORE_MAX_PEOPLE = 12;
export const INSHORE_MAX_HOURS = 8;
export const INSHORE_START_TIME = 7;
export const INSHORE_DEPOSIT = 15;
export const INSHORE_DURATION_OPTIONS = [
        {'id':4, 'label':'4 Hours'},
        {'id':5, 'label':'5 Hours'},
        {'id':6, 'label':'6 Hours'},
        {'id':7, 'label':'7 Hours'},
        {'id':8, 'label':'8 Hours'}
    ];

export default {
    HARBOR_CRUISE,
    HALF_DAY_TRIP,
    FULL_DAY_TRIP,
    THREE_FOURTHS_DAY_TRIP,
    MIN_DAYS_TO_CHANGE_HALF_DAY_TRIP,
    TEASER_ID,

    INSHORE_NAME,
    INSHORE_BASE_PEOPLE,
    INSHORE_BASE_HOURS,
    INSHORE_BASE_COST,
    INSHORE_ADD_COST,
    INSHORE_MAX_PEOPLE,
    INSHORE_MAX_HOURS,
    INSHORE_START_TIME,
    INSHORE_DEPOSIT,
    INSHORE_DURATION_OPTIONS
}