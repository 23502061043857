<template>
  <div class="book-layout">
    <div v-if="customerBooked" class="text-center">
      <feather-icon
          icon="CheckCircleIcon"
          size="64"
          class="mb-2 mt-5 text-success"
      />
      <h2>
        Thanks for booking! Please check your email for confirmation and further
        details. We look forward to your trip!
      </h2>
    </div>
    <template v-else>
      <b-row class="mb-2">
        <b-col>
          <h1>Book Harbor Cruise!</h1>
        </b-col>
      </b-row>
      <div v-if="isLoading" class="text-center pt-3">
        <b-spinner label="Loading..." />
      </div>
      <template v-else>
        <validation-observer ref="bookTripValidation">
          <b-row class="mb-3">
            <b-col cols="12">
              <b-card-group deck class="justify-content-around">
                <b-card
                    v-for="boat in boatNamesTripsPictures"
                    v-if="boat.id == TEASER_ID"
                    :key="boat.id"
                    no-body
                >
                </b-card>
              </b-card-group>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col md="4">

              <template v-if="chosenBoat === 0">
                <h5>Select your trip duration:</h5>
                <validation-provider
                    #default="{ errors }"
                    name="duration"
                    rules="required"
                >
                  <v-select
                      id="duration"
                      v-model="duration"
                      placeholder="Select the trip duration"
                      label="label"
                      :options="INSHORE_DURATION_OPTIONS"
                      :clearable="false"
                  />
                  <small class="text-danger d-block mb-2 mt-1">{{errors[0]}}</small>
                </validation-provider>
              </template>

              <h5>Select your date:</h5>
              <p>Blocked dates are unavailable</p>
              <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
              >
                <b-form-datepicker
                    id="datepicker-boat"
                    v-model="chosenDate"
                    :min="minDate"
                    :date-disabled-fn="dateDisabled"
                    :disabled="isDatepickerDisabled"
                    :placeholder="datepickerPlaceholder"
                    locale="en"
                    @input="datepickerUpdated"
                />
                <small v-if="lastMinuteCheck" class="text-danger d-block mb-1 mt-1">
                  Last minute bookings are subject to boats and captains availability. Please confirm via text with our team after you book to make sure we can accommodate your request.
                </small>
                <small class="text-danger d-block mb-2 mt-1">{{
                    errors[0]
                  }}</small>
              </validation-provider>
              <template
                  v-if="
                  chosenBoat > 0 &&
                  chosenBoat === TEASER_ID &&
                  (chosenCharterPackage === HALF_DAY_TRIP || chosenCharterPackage === HARBOR_CRUISE) &&
                  chosenDate !== null
                "
              >
                <h5>Choose the time </h5>
                <validation-provider
                    #default="{ errors }"
                    name="time"
                    rules="required"
                >
                  <v-select
                      id="select-charter-package"
                      v-model="chosenTime"
                      placeholder="Select package"
                      label="text"
                      :options="times"
                      :clearable="false"
                      :selectable="timesDisabled"
                  />
                  <small class="text-danger d-block mb-2 mt-1">{{
                      errors[0]
                    }}</small>
                </validation-provider>
              </template>
            </b-col>
            <b-col md="4" class="mb-2">
              <h5>Your Full Name</h5>
              <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
              >
                <b-form-input
                    id="input-name"
                    v-model="details.name"
                    placeholder="Enter your name"
                    required
                />
                <small class="text-danger d-block mb-2 mt-1">{{
                    errors[0]
                  }}</small>
              </validation-provider>

              <h5>Your Email</h5>
              <p>Confirmation email will be sent here.</p>
              <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
              >
                <b-form-input
                    id="input-email"
                    v-model="details.email"
                    placeholder="Enter your email"
                    required
                    type="email"
                />
                <small class="text-danger d-block mb-2 mt-1">{{
                    errors[0]
                  }}</small>
              </validation-provider>

              <h5>Your Phone</h5>
              <p>Confirmation text will be sent here.</p>
              <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required|min:12"
              >
                <phone-mask-input
                    v-model="details.phone"
                    defaultCountry="us"
                    inputClass="form-control"
                />
                <small class="text-danger d-block mb-2 mt-1">{{
                    errors[0]
                  }}</small>
              </validation-provider>

              <h5>How Many People Will Be In This Group?</h5>
              <validation-provider
                  ref="detailsInput"
                  v-slot="{ errors }"
                  :rules="'max-value:' + (chosenBoat > 0 ? passengers[chosenBoat - 1] : INSHORE_MAX_PEOPLE)"
              >
                <v-select
                    id="input-details"
                    v-model="details.details"
                    :options="guestsOptions"
                    :clearable="false"
                />
                <small v-if="errors" class="text-danger d-block mb-2 mt-1">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-col>
            <b-col md="4" class="mb-1">
              <template v-if="user && user.card">
                <h5>Currently you use this payment method:</h5>
                <b-card
                    class="payment-card border-secondary mb-1 w-100"
                >
                  <div class="d-flex align-items-baseline justify-content-between">
                    <h3 class="mb-0">
                      {{ user.card.brand }}
                    </h3>
                    <div class="d-flex">
                      <h4>
                        •••• {{ user.card.last4 }}
                      </h4>
                      <span class="ml-25">(expires {{ user.card.exp_month }}/{{ user.card.exp_year }})</span>
                    </div>
                  </div>
                </b-card>
                <p>
                  If you want to change your payment method <b-link :to="{ name: 'profile' }">click here</b-link>
                </p>
              </template>
              <template v-else>
                <h5>Please enter your payment details:</h5>
                <p>We do not store any card details on our server. Everything is securely transmitted to our card processing vendor Stripe.</p>
                <stripe-elements
                    #default="{ elements }"
                    ref="elms"
                    :stripe-key="stripePublishKey"
                    :elements-options="elementsOptions"
                >
                  <stripe-element
                      ref="card"
                      type="card"
                      :elements="elements"
                      :options="cardOptions"
                      @change="errorHandler($event)"
                  />
                </stripe-elements>
                <div
                    id="card-errors"
                    class="mt-1"
                    role="alert"
                    v-text="errorMessage"
                />
              </template>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row class="mb-1">
          <b-col>
            <h3>
              {{ bookingDate }}
              {{ `GUESTS: ${Number(details.details)}` }}
              {{ bookingFullPrice }}
              {{ bookingDeposit }}
            </h3>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <b-form-checkbox
                v-model="termsAgreed"
            >
              I agree to <b-link v-b-modal.terms-modal>terms & conditions.</b-link>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="pb-2">
          <b-col class="d-flex justify-content-end">
            <b-button
                variant="success"
                :disabled="isBookingLoading || !termsAgreed"
                class="w-100"
                @click="bookTripSubmit"
            >
              <b-spinner v-if="isBookingLoading" label="Spinning" small />
              <span v-else>BOOK MY TRIP!</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </template>

    <b-modal
        id="terms-modal"
        ok-only
        title="Terms & Conditions"
        size="lg"
    >
      Disclaimer: While we at Wahoo Fishing Charters try our best to 1) keep our vessels neat, clean, tidy and organized 2) accurately describe our boats 3) accurately describe our services and normal customer experience, we can not and do not warrant or guarantee, in any way 1) that any particular feature or system of any boat will be in working order 2) the overall condition of any vessel 3) that any particular kind of fishing will be attempted (either trolling or bottom fishing, etc...the captain will decide what kind of fishing will be done based on circumstances) 4) that any particular species will be caught or targeted.
      <br><br>
      Furthermore, clients understand and recognize that 1) if any reservation is canceled by the client more than 30 days in advance of the departure date a full refund will be granted 2) if the client cancels a reservation inside 30 days but more than 7 days prior to the departure, the client forfeits their 50% deposit 3) if a client cancels their reservation inside seven days they are responsible for the full payment of the charter 4) once the client is on the boat and the boat leaves the dock, no client is entitled to any discount or any refund for any reason whatsoever. By completing this booking you are indicating you have read and agree to these terms.
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { maxValue } from "@/libs/validations";
import {
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardBody,
  BFormRadio,
  BFormDatepicker,
  BFormInput,
  BButton,
  BSpinner,
  BLink,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import axios from "@/libs/axios";
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";
import {toastFailure} from "@/libs/toastification";
import {sweetWarning, sweetError} from "@/libs/sweet-alerts";
import PhoneMaskInput from  "vue-phone-mask-input";
import {testStripeToken} from "@/libs/stripe-test-token";
import {checkReservedDate, isTimeSelectable} from "@/libs/reserved-dates";
import { HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID,
  INSHORE_NAME,
  INSHORE_BASE_PEOPLE,
  INSHORE_BASE_HOURS,
  INSHORE_BASE_COST,
  INSHORE_ADD_COST,
  INSHORE_MAX_PEOPLE,
  INSHORE_MAX_HOURS,
  INSHORE_START_TIME,
  INSHORE_DEPOSIT,
  INSHORE_DURATION_OPTIONS} from "@/constants/trip-types.enum";
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardBody,
    BFormRadio,
    BFormDatepicker,
    vSelect,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    StripeElements,
    StripeElement,
    PhoneMaskInput,
    BLink,
    BFormCheckbox,
  },
  data() {
    const { inshoreTripImage } = $themeConfig.app;
    return {
      inshoreTripImage,
      firstApril2022: new Date(2022, 4, 1),
      isLoading: true,
      stripePublishKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      elementsOptions: {
        locale: "en-US",
      },
      stripeElementValue: null,
      chosenBoat: TEASER_ID,
      minDate: new Date(),
      datepickerPlaceholder: "No date selected",
      isDatepickerDisabled: false,
      chosenDate: null,
      blockDates: [],
      chosenCharterPackage: HARBOR_CRUISE,
      duration: {'id':INSHORE_BASE_HOURS, 'label':INSHORE_BASE_HOURS+' Hours'},
      thisBoatTripNameId:[],
      chosenTime: null,
      details: {
        name: null,
        email: null,
        phone: "+1",
        details: 2,
      },
      passengers: [6, 6, 20],
      required,
      email,
      maxValue,
      HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID,
      INSHORE_NAME,
      INSHORE_BASE_PEOPLE,
      INSHORE_BASE_HOURS,
      INSHORE_BASE_COST,
      INSHORE_ADD_COST,
      INSHORE_MAX_PEOPLE,
      INSHORE_MAX_HOURS,
      INSHORE_START_TIME,
      INSHORE_DEPOSIT,
      INSHORE_DURATION_OPTIONS,
      errorMessage: "",
      bookingErrorMessage: "",
      cardOptions: {
        hidePostalCode: true,
      },
      termsAgreed: false,
      isBookingLoading: false,
      customerBooked: false,
    };
  },
  computed: {
    ...mapGetters({
      boatNamesTripsPictures: "boats/boatNamesTripsPictures",
      user: "auth/user",
    }),
    guestsOptions(){

      let options = [];
      for( let i=1; i <= (this.chosenBoat > 0 ? this.passengers[this.chosenBoat - 1] : INSHORE_MAX_PEOPLE); i++ ){
        options.push(i);
      }
      return options;
    },
    charterPackages() {
      const packages = [];
      if( this.chosenBoat > 0 ){
        let that = this;
        this.boatNamesTripsPictures[this.chosenBoat - 1].trips.map(function(trip){

          if( trip.inactive === 0 ){
            packages.push(trip.name);
          }
        });
      }
      return packages;
    },
    times() {
      const timeOptions = [
        {
          value: "06:00:00",
          text: "6 AM",
          selectable: isTimeSelectable("6 AM", this.blockDates, this.setEdited()),
        },
        {
          value: "12:00:00",
          text: "12 PM",
          selectable: isTimeSelectable("12 PM", this.blockDates, this.setEdited()),
        }
      ];

      if (this.chosenCharterPackage === HARBOR_CRUISE) {
        timeOptions.push({
          value: "18:00:00",
          text: "6 PM",
          selectable: isTimeSelectable("6 PM", this.blockDates, this.setEdited()),
        });
      }

      return timeOptions;
    },
    bookingDeposit() {
      if (!this.chosenCharterPackage) {
        if( this.chosenBoat == 0 ){
          return `| DUE TODAY: $${this.calculatePrice()*INSHORE_DEPOSIT/100}`;
        }
        return "";
      }

      let deposit = this.calculatePrice() * .1;
      if (this.chosenCharterPackage === HARBOR_CRUISE) deposit = 300;

      return `| DUE TODAY: $${deposit}`;
    },
    bookingFullPrice() {
      if (!this.chosenCharterPackage){
        if( this.chosenBoat == 0 ){
          return `| TOTAL: $${this.calculatePrice()}`;
        }
        return "";
      }

      return `| TOTAL: $${this.calculatePrice()}`;
    },
    bookingDate() {
      if (!this.chosenDate) return "";
      return `DATE: ${new Date(this.chosenDate).toLocaleString("en-US", {
        weekday: "long",
        day: "numeric",
        year: "numeric",
        month: "long",
        timeZone: "Europe/London",
      })} |`;
    },
    lastMinuteCheck() {
      const date = new Date();

      if (this.chosenDate && Date.parse(this.chosenDate) < date.setDate(date.getDate() + 1)) {
        return true;
      }

      return false;
    },
  },
  watch: {
    chosenBoat() {
      this.loadBlockDates();
      this.chosenCharterPackage = null;
    },
    chosenCharterPackage() {
      this.loadBlockDates();
    },
  },
  mounted() {
    this.loadBoatNamesTripsPictures()
        .then(() => {
          if (this.user) {
            this.details.name = this.user.name;
            this.details.email = this.user.email;
            this.details.phone = this.user.phone;
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.showError();
        });
  },
  methods: {
    ...mapActions({
      loadBoatNamesTripsPictures: "boats/loadBoatNamesTripsPictures",
      createBooking: "bookings/createBooking",
      payAndCreateBooking: "bookings/payAndCreateBooking",
    }),

    setEdited(){
      return {
        boatObject: null,
        boat: {
          id :this.chosenBoat
        },
        charterPackage: this.chosenCharterPackage,
        id: null,
        time: this.chosenTime ? this.chosenTime.value : this.getDefaultStartTime(),
        date: this.chosenDate
      };
    },
    dateDisabled(date) {
      if( this.chosenBoat > 0 ){
        return checkReservedDate(date, this.blockDates, this.setEdited(), this.boatNamesTripsPictures, this.thisBoatTripNameId);
      }
      else{
        return false;
      }
    },
    bookTripSubmit() {
      this.$refs.bookTripValidation.validate().then((success) => {
        const groupComponent = this.$refs.elms;

        this.isBookingLoading = true;

        if (groupComponent) {
          const cardComponent = this.$refs.card;
          const cardElement = cardComponent.stripeElement;
          groupComponent.instance.createToken(cardElement).then((response) => {
            if (success && !response.error) {

              let tokenId = '';
              let last4 = response.token.card.last4;

              let testTokenId = testStripeToken(last4)
              if( testTokenId ){
                tokenId = testTokenId;
              }
              else{
                tokenId = response.token.id;
              }
              this.creatingBooking(tokenId);
              return;
            }

            this.isBookingLoading = false;
          });
        } else {
          this.creatingBooking();
        }
      });
    },
    timesDisabled(option) {
      return option.selectable;
    },
    disableSelectedDate(){
      this.chosenDate = null;
      this.datepickerPlaceholder = 'Please select a new date';
    },
    loadBlockDates() {
      if (this.chosenBoat && this.chosenBoat > 0 && this.chosenCharterPackage) {
        this.chosenDate = null;
        this.datepickerPlaceholder = "Loading...";
        this.isDatepickerDisabled = true;
        axios
            .get(
                `api/bookings/block-dates/${this.chosenBoat}/${this.chosenCharterPackage}`
            )
            .then((response) => {
              const blockDates = {};
              for (const date of response.data) {

                if( this.chosenDate == date.date ){
                  this.disableSelectedDate();
                }

                if (!blockDates[date.date]) {
                  blockDates[date.date] = new Array();
                }

                blockDates[date.date].push(date);
              }

              this.blockDates = blockDates;
              this.datepickerPlaceholder = "No date selected";
              this.isDatepickerDisabled = false;

              if( this.dateDisabled(this.chosenDate)  ){
                this.disableSelectedDate();
              }
            })
            .catch(() => {
              this.showError();
            });
      }
    },
    isSelectableBlocked(time) {
      if (this.blockDates[this.chosenDate]) {
        /* eslint-disable-next-line */
        return this.blockDates[this.chosenDate].time.includes(time) ? false : true;
      }
      return true;
    },
    datepickerUpdated() {
      this.chosenTime = null;
    },
    errorHandler(event) {
      this.errorMessage = event.error ? event.error.message : "";
    },
    showError(responseData=null, warning=false) {

      if( responseData != null && responseData.message != undefined && responseData.message != null ){

        let errorMSG = responseData.message + '</br>';
        if(responseData.errors){

          Object.keys(responseData.errors).map(function(errorKey){
            responseData.errors[errorKey].map(function(value, key){
              errorMSG += value + '</br>';
            });
          });
        }

        if( warning ){
          sweetWarning('Warning', errorMSG);
        }
        else{
          sweetError('Error', errorMSG);
        }

      }
      else{
        toastFailure("Something went wrong.");
      }
    },
    creatingBooking(stripeToken = null) {

      let calcPrice = this.calculatePrice();
      this.payAndCreateBooking({
        boat_id: this.chosenBoat,
        date: this.chosenDate,
        status: 2,
        charter_package: this.chosenBoat > 0 ? this.chosenCharterPackage : INSHORE_NAME,
        time: this.chosenTime && this.chosenBoat > 0 ? this.chosenTime.value : this.getDefaultStartTime(),
        name: this.details.name,
        email: this.details.email,
        phone: this.details.phone,
        details: this.details.details,
        total_price: calcPrice,
        trip_id: this.chosenBoat > 0 ? this.thisBoatTripNameId[this.chosenCharterPackage] : null,
        duration: this.chosenBoat === 0 ? this.duration.id : null,
        amount: this.chosenBoat > 0 ? (this.chosenCharterPackage === HARBOR_CRUISE ? 300 : calcPrice * .1) : (calcPrice * INSHORE_DEPOSIT / 100),
        stripeToken
      }).then((response) => {

        if( response.data.sms_sent === false ){
          let smsError = {
            message:'Booking successfully created, but we couldn\'t send you a SMS notification, please check the mobile number you provided and make sure it is able to receive messages.'
          };
          this.showError(smsError, 1);
        }

        this.customerBooked = true;
      }).catch((error) => {
        this.showError(error.response.data);
        this.isBookingLoading = false;
      });
    },
    calculatePrice() {

      const selectedBoatTripNameId = [];
      const thisBoatPrices = [];
      const thisBoatAdditionalPrices = [];

      if( this.chosenBoat > 0 ){
        this.boatNamesTripsPictures[this.chosenBoat - 1].trips.map(function(trip){
          thisBoatPrices[trip.name] = trip.price;
          selectedBoatTripNameId[trip.name] = trip.id;
          thisBoatAdditionalPrices[trip.name] = trip.additional_price == null ? 0 : trip.additional_price;
        });

        this.thisBoatTripNameId = selectedBoatTripNameId;

        if (this.chosenCharterPackage === HARBOR_CRUISE) return 800;
        if (this.chosenBoat === TEASER_ID && this.details.details > 6) {

          const additionalPrice = thisBoatAdditionalPrices[this.chosenCharterPackage];
          return thisBoatPrices[this.chosenCharterPackage] + additionalPrice * (this.details.details - 6);
        }

        return thisBoatPrices[this.chosenCharterPackage];
      }
      else{

        let additionalPrice = (this.details.details > INSHORE_BASE_PEOPLE ? (this.details.details - INSHORE_BASE_PEOPLE)*INSHORE_ADD_COST : 0) + (this.duration.id - INSHORE_BASE_HOURS)*INSHORE_ADD_COST;
        return INSHORE_BASE_COST + additionalPrice;
      }

    },
    getDefaultStartTime() {
      return this.chosenBoat === 0 ? "0"+INSHORE_START_TIME+":00:00" : (this.chosenBoat === TEASER_ID ? "06:00:00" : "05:00:00");
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/bookTrip.scss";
@import "../assets/scss/stripeStyles.scss";
</style>
