
export function testStripeToken(last4) {

    /*
    For more options check this page
    https://stripe.com/docs/testing?numbers-or-method-or-token=tokens#cards
    */

    const last4ToToken = {

        '0069': 'tok_chargeDeclinedExpiredCard', //4000000000000069 Expired card decline
        '9995': 'tok_visa_chargeDeclinedInsufficientFunds', //4000000000009995 Insufficient funds decline
        '0127': 'tok_chargeDeclinedIncorrectCvc', //4000000000000127 Incorrect CVC decline
        '4241': 'tok_chargeDeclinedIncorrectNumber', //4242424242424241	 Incorrect number decline
        '0002': 'tok_visa_chargeDeclined', //4000000000000002 Generic decline
        '0119': 'tok_chargeDeclinedProcessingError', //4000000000000119 Generic decline

        '0341': 'tok_visa_chargeDeclined', //4000000000000341 Decline after attaching

        '4242': 'tok_visa', //4242424242424242 Visa
        '4444': 'tok_mastercard', //5555555555554444 Mastercard
    };

    if( process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY.includes("pk_test_") ){

        console.log('We are in testing mode');
        if( last4ToToken[last4] != undefined ){
            return last4ToToken[last4];
        }
    }
    else{
        return false;
    }
}